
import { apiPartnerLists, apiPartnerStatus, apiChangeRemark, apiPartnerSso } from '@/api/partner'
import { Component, Prop, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
// import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
@Component({
    components: {
        lsPagination,
        // ExportData,
        LsDialog
    }
})
export default class PartnerList extends Vue {
    /** S Data **/

    // apiPartnerLists = apiPartnerLists;

    pager: RequestPaging = new RequestPaging();

    searchObj: any = {
        status: '',
        keyword: '',
        create_time: ''
    };
    /** E Data **/

    /** S Methods **/

    // 获取合作商列表
    getPartnerLists(page?: number): void {
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiPartnerLists,
            params: {
                ...this.searchObj
            }
        })
    }

    // 冻结合作商
    async onPartnerStatus(row: any): Promise<void> {
        await apiPartnerStatus({ id: row.id, status: row.status == 1 ? 0 : 1 })
        this.getPartnerLists()
    }

    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, '')
        })
        this.getPartnerLists()
    }

    async changeRemarks(text: string, id: number): Promise<void> {
        await apiChangeRemark({ id: id, remark: text })
        this.getPartnerLists()
    }

    // 去编辑合作商
    toPartnerEdit(id: number | any): void {
        this.$router.push({
            path: '/partner/lists_detail',
            query: {
                id: id
            }
        })
    }

    // 修改账号
    toAccountUpdate(id: number | any): void {
        this.$router.push({
            path: '/partner/modify_account',
            query: {
                id: id
            }
        })
    }

    // 去合作商后台
    async toPartner(url: any, status: number, id: number) {
        if (status == 0) return this.$message.error('暂未营业中')

        const { token, name } = await apiPartnerSso({ id: id })

        url = url + '/account/login?token=' + token + '&name=' + name
        if (url.indexOf('https://') === 0) window.open(url)
        else if (url.indexOf('http://') === 0) window.open(url)
        else window.open('http://' + url)
    }

    /** E Methods **/

    /** S ods **/
    created() {
        this.getPartnerLists()
    }
}
